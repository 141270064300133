*{
  font-family: Arial, Helvetica, sans-serif;
  color:#4B4B4B;
}

a{
  text-decoration:none; 
}

.navbar{
  background-color: #15af97 !important;
  box-shadow: 0 3px 5px 0 rgb(50 50 50 / 50%);
  height: 75px;
  padding: 0 80px;
}

.navbar-brand{
  padding-top: 0;
}

.navbar img{
  width: 140px;
}

.navbar input[type=search]{
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  font-size: 14px;
  width: 500px;
}

.navbar form .fa-search{
  color: #15af97;
  padding: 0.75rem;
  margin-left: -10%;
}

.navbar .mobile-search{
  display: none;
  order: 2;
}

/* -------------------------------------------- */

.navbar-toggler {
  order: 3;
  border: none;
}

.navbar-toggler span {
  display: block;
  background-color: white;
  height: 5px;
  width: 35px;
  margin-top: 7px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  border-radius: 35px;
  transition: all 0.35s ease-out;
  transform-origin: center left;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
  width: 38px;
}

.navbar-toggler span:nth-child(2) {
  opacity: 1;
  width: 38px;
}

.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
  width: 38px;
}

.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
}

.navbar .offcanvas{
  max-width: 80% !important;
}

.navbar .offcanvas .btn-close{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.navbar .offcanvas img{
  width: 60%;
  margin: 25px auto 0 auto;
}

.navbar .offcanvas .nav-link{
  margin-left: 15%;
}

.navbar .offcanvas .languages{
  position: absolute;
  bottom: 10%;
  left: 10%;
}

.navbar .offcanvas .languages ul{
  margin-top: 10px;
  list-style-type: none;  
}

.navbar .offcanvas .languages ul li img{
  margin:10px 0 30px 15px;
  width: 30px;
}

.navbar .offcanvas .languages ul li img:hover{
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

/* -------------------------------------------- */

.sectors {
  padding: 2% 0 2% 6%; 
  position: relative;
}

.sectors h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.sectors-slider{
  display: grid;
  grid-auto-columns: minmax(0, 75px);
  grid-auto-flow: column;
  gap: 2rem;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  margin-top: 25px;
  height: 125px;
}

.sectors-slider > div{
  scroll-snap-align: start;
}

.sectors-slider .sector {
  position: relative;
  flex: 10px;
  width: 75px;
  height: 75px;
  object-fit: contain;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  filter: grayscale(40%) !important;
  -webkit-filter: grayscale(40%) !important;
}

.sectors-slider .sector_icon{
  text-align: center;
  width: 95%;
  height: 95%;
  margin: 2.5%;
  padding-top: 20%;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.sectors-slider .sector_icon.active{
  border: 3px solid white;
}

.sectors-slider .sector p.active{
  color: rgb(70, 70, 70);
  font-weight: 600;
}

.sectors-slider i{
  font-size: 35px;
  color: white;
}

.sectors-slider p{
  text-align: center;
  margin-top: 10px;
}

.sectors-slider::-webkit-scrollbar {
  display: none;
}

.language-reference{
  position: absolute;
  right: 7%;
  top: 20%;
}

/* -------------------------------------------- */

.videos{
  padding: 0% 0 2% 1%;

}

.letter {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 1%;
}

.line {
  border-bottom: 1px solid rgb(224, 224, 224);
  background-color: #DADADA;
  height: 1px;
  margin-left: 5px;
  width: 98%;
}

.line span {
  display: block;
  width: 30px;
  height: 1px;
  background-color: #4B4B4B;
  
}

/* -------------------------------------------- */

.video-slider{
  height: auto;
  overflow: hidden;
  padding-bottom: 40px;
  z-index: 998;
  padding:2% 6% 35px 6%;
  text-align: left;
}

.video-slider .swiper-slide{
  cursor: pointer;
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  padding-bottom: 20px;
}

.video-slider .swiper-slide-active{
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
}

.video-slider .swiper-pagination{
  padding-top: 20px;
  position: initial;
}

.video-slider .swiper-pagination-bullet-active{
  background: #15af97
} 

.swiper-button-prev{
  top: 40%;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgb(89, 89, 89);
  
}

.swiper-button-prev::after{
  font-size: 20px;
  font-weight: 900;
  color: white;
  margin-right: 10%;
  
}

.swiper-button-next{
  top: 40%;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgb(89, 89, 89);
  
}

.swiper-button-next::after{
  font-size: 20px;
  font-weight: 900;
  color: white;
  margin-left: 10%;
  
}

.slider_card{
  -webkit-box-shadow: 4px 5px 12px -2px rgba(0,0,0,0.2);
  -moz-box-shadow: 4px 5px 12px -2px rgba(0,0,0,0.2);
  box-shadow: 4px 5px 12px -2px rgba(0,0,0,0.2);
  border-radius: 15px;
  width: 97% !important;
  height: 360px;
}

.slider_card:focus{
  outline:0px !important;
}

.slider_card .rounded-pill {
  position: absolute;
  margin: 3% 6%;
  right: 0;
}

.slider_card .fa-play{
  position: absolute;
  color: white;
  margin: 28% 0px 0px 45%;  
  font-size: 35px;
  opacity: 0.7;
  z-index: 998;
}

.slider_card .fa-play:hover{
  opacity: 1;
}

.slider_card img {
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.slider_card .card_info{
  padding:15px 10px;
}

.slider_card .card_info h2{
  margin-block-start: 0em;
  margin-block-end: 0.5em; 
  font-size: 1.3rem;
  font-weight: 600;
}


/* -------------------------------------------- */

.videoPage{
  padding: 2% 0 2% 1%;
}

.videoPage .fa-right-from-bracket{
  font-size: 2.2rem;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  margin-right: 40px;
}
.videoPage h1{
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 40px;
}

.react-player{
  width: 100% !important;
  height: 270px  !important;
}

.react-player__preview{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.react-player video{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}


input[type='range']::-webkit-slider-thumb {
  cursor: ew-resize;
  background: #15af98;
}

.translation{
  padding-left: 15px;
}

.translation h3{
  padding-left: 20px;
}

.translation hr{
  margin-bottom: 30px;
}

.translation p {
  margin-left: 20px;
}

.description{
  margin-top: 25px;
  margin-bottom: 25px;
}

.fingerspelling{
  margin-top: 25px;
  margin-bottom: 25px;
}

.synonyms{
  margin-top: 25px;
  margin-bottom: 25px;
}




/* -------------------------------------------- */

@media screen and (max-width: 1500px) {
  .navbar .offcanvas img{
    width: 175px; 
  }

  .navbar .offcanvas h4{
    font-size: 1.2rem !important;
  }

  .navbar .offcanvas .languages{
   bottom: 0;
  }

  
}



@media screen and (max-width: 950px) {
  .navbar{
    padding: 0 ;
  }

  .navbar-brand{
    margin-left: 2%;
  }

  .navbar input[type=search]{
    display: inherit;
    width: 100%;
  }

  main .sectors{
    padding: 6% 0 2% 2%;
  }

  main .sectors-slider{
    grid-auto-columns: auto;
    gap: 1rem;
  }

  .video-slider{
    padding: 2% 0 35px 2%;
  }
  
}

@media screen and (max-width: 768px) {

  .navbar {
    height: auto;
    padding-top: 15px;
    padding-bottom: 12px;
  }

  .navbar .search{
    display: none !important;
    width: 100%;
    order: 5;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .navbar .active{
    display: flex !important;
    
  }

  .navbar .search input {
    margin-right: 0 !important;
  }


  .navbar .mobile-search{
    display: flex;
    flex: auto;
    font-size: 30px;
    color: white;
    justify-content: right;
  }

  .navbar .mobile-search i{
    color: white;
  }
  
  .navbar .offcanvas .languages ul{
    padding-left: 0px;
  }
  
  .navbar .offcanvas .languages li img:first-child{
    margin-left: 0;
  }

  main .sectors .col-md-12{
    padding-right: 0;
  }

  main .sectors-slider{
    gap: 1.5rem
  }

  .sectors-slider .sector{
    width: 65px;
    height: 65px;
  }

  .sectors-slider .sector i{
    font-size: 32px;
  }

  .sectors-slider .sector p{
    font-size: 14px;
  }

  .video-slider{
    padding: 5% 0 35px 4%;
  }

  .video-slider .swiper-button-next{
    right: 20px;
  }
}
  

